import React from 'react'
import { navigate } from 'gatsby'
import { AdminLayout, CreateEditPlans } from 'widgets'
import {Button, Table, SEO, Icon, Pagination, Modal} from 'components'
import { quitLoading, setLoading, showError, confirm, showSuccess } from 'utils'
import { PlanService } from 'services'
import { Pagination as PaginationModel, Plan } from 'models'

const PlansAndCoverage = () => {
    const [plans, setPlans] = React.useState<Plan[]>([])
    const [pagination, setPagination] = React.useState<PaginationModel | null>(null)
    const [visible, setVisible] = React.useState<boolean>(false)
    const [element, setElement] = React.useState<Plan | null>(null)
    const load = async (page = pagination?.current_page || 1) =>{
        try {
            setLoading()
            const {data: _plans, pagination: _pagination} = await PlanService.get(page)
            setPlans(_plans)
            setPagination(_pagination)
        } catch (error) {
            showError()
            navigate('/')
        }finally{
            quitLoading()
        }
    }
    React.useEffect(() => {
        load()
    }, [])
    
    const getIconByPlan = (key: keyof Plan, element: Plan) => {
        let icon = 'check text-success'
        if(element[key] !== 'y')
            icon = 'close text-danger'

        return icon
    }
    const editItem = (element: Plan) => {
        setElement(element)
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
        setElement(null)
        load()
    }
    const deleteItem = (plan: Plan) => {
        confirm('Seguro desea eliminar el plan: '+plan.name+' ?', async () => {
            try {
                setLoading()
                const {msg} = await PlanService.delete(plan)
                showSuccess(msg)
            } catch (error) {
                showError(error?.response?.data?.msg)
            }finally{
                quitLoading()
                load()
            }
        })
    }
    return(
        <AdminLayout>
            <SEO
                title="Planes y coberturas"
            />
            <Modal
                visible={visible}
                onClose={onClose}
                title="Crear / Editar Planes y coberturas"
            >
                <CreateEditPlans
                    element={element}
                    onClose={onClose}
                />
            </Modal>
            <div className="plans-and-coverage">
                <div className="container">
                    <Table
                        header={
                            ['#', 'Nombre', 'Proveedor', 'Coberturas' ,'Centro Am.', 'Europa', 'Norte Am.', 'Sur Am.', 'Mundo', 'Acciones']
                        }
                        data={plans.length}
                        title="Planes y coberturas"
                        right={
                            <Button
                                icon="plus"
                                className="primary"
                                small
                                onClick={() => setVisible(true)}
                            />
                        }
                    >
                        {
                            plans?.map((element, i) => {
                                return(
                                    <tr key={ i }>
                                        <th scope="row">{ element.id }</th>
                                        <td className="ellipsis"> {element.name} </td>
                                        <td className="ellipsis"> {element.insurance?.name || 'Sin proveedor'} </td>
                                        <td> {(element.items?.length || 0)+' Cob.' } </td>
                                        <td>
                                            <Icon
                                                name={getIconByPlan('centralAmerica', element)}
                                            />
                                        </td>
                                        <td>
                                            <Icon
                                                name={getIconByPlan('europe', element)}
                                            />
                                        </td>
                                        <td>
                                            <Icon
                                                name={getIconByPlan('northAmerica', element)}
                                            />
                                        </td>
                                        <td>
                                            <Icon
                                                name={getIconByPlan('southAmerica', element)}
                                            />
                                        </td>
                                        <td>
                                            <Icon
                                                name={getIconByPlan('world', element)}
                                            />
                                        </td>
                                        <td>
                                            {/* <Button
                                                icon="eye"
                                                onClick={() => viewUser(element)}
                                                small
                                            /> */}
                                            <Button
                                                icon="edit"
                                                className="info text-white"
                                                onClick={() => editItem(element)}
                                                small
                                            />
                                            <Button
                                                icon="trash"
                                                className="danger text-white"
                                                onClick={() => deleteItem(element)}
                                                small
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </Table>
                    <Pagination 
                        pages={ pagination?.last_page || 1 } 
                        active={ pagination?.current_page || 1 }
                        onChange={ async (page: number) => {
                                if(pagination){
                                    const _pagination: PaginationModel = {
                                        ... pagination,
                                        current_page: page
                                    }
                                    setPagination(_pagination)
                                    load(_pagination.current_page)
                                }
                            }
                        }
                    />
                </div>
            </div>
        </AdminLayout>
    )
}

export default PlansAndCoverage